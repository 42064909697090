$(function () {
    showVoteModal = function (integrationName) {
        $('#client_vote_modal').modal('show');
        $('textarea[name=\'client_vote[comment]\']').val('');
        input = $('input[name=\'client_vote[feature_name]\']');
        let val = input.val().split('/').slice(0, -1).join('/') + '/'
        input.val(val + integrationName);
    };

    $('#amo-integration').on('change', '#funnels_options_container input[name="amo_integration_setting[same_customer_funnel_for_all]"]', function () {
        if ($(this).val() == '0') {
            $('#funnels_per_managers .funnel-options').slideDown(500);
            $('#same_funnel_for_all .funnel-options').slideUp(500);
        } else {
            $('#funnels_per_managers .funnel-options').slideUp(500);
            $('#same_funnel_for_all .funnel-options').slideDown(500)
        }
    });

    $('#amo-integration').on('change', '.funnel-id-select', function () {
        new_options_html = ''
        $($('#funnels_options_container').data('funnels-statuses')[$(this).val()]).each(function (index, elem) {
                new_options_html += '<option value="' + elem[0] + '">' + elem[1] + '</option>'
            }
        )
        $(this).parents('.funnel-with-status-row').find('.funnel-status-id-select').html(new_options_html)
    });

    let showHidePipelineBlock = function (call_type) {
        if ($('#amo_integration_setting_' + call_type + '_search_in_pipeline_type_uniq_pipeline').is(':checked')) {
            $('#' + call_type + '_search_active_lead_in_pipeline_hidden').show()
        } else {
            $('#' + call_type + '_search_active_lead_in_pipeline_hidden').hide()
        }
        if ($('#amo_integration_setting_' + call_type + '_search_in_pipeline_type_managers_pipeline').is(':checked')) {
            $('#' + call_type + '_search_active_lead_with_phone_hidden').show()
        } else {
            $('#' + call_type + '_search_active_lead_with_phone_hidden').hide()
        }
    };

    $('#amo-integration').on('change', '.inbound_search_active_lead_in_pipeline_block', function () {
        showHidePipelineBlock('inbound');
    })

    $('#amo-integration').on('change', '.outbound_search_active_lead_in_pipeline_block', function () {
        showHidePipelineBlock('outbound');
    })
});

$(function () {
    /* TODO: extract common parts with origin calls for later usage */
    /* Player section */
    new Plyr($('.record-player > .plyr-player'));
    let playerNode = $('.record-player audio').get(0)
    let $playerWrapper = $('.record-player')
    window.playStopVoiceRecord = function ($elem, player_class) {
        if ($elem.hasClass('play_record')) {
            $('.play_stop_record.stop_record').click()
            $playerWrapper.attr('class', player_class.slice(1))
            let offset = $elem.offset()
            $lastOriginCallRecord = $elem
            offset.left -= 470
            offset.top -= 5
            $playerWrapper.show()
            $playerWrapper.offset(offset)
            let recordSrc = $elem.attr('record_src')
            playerNode.setAttribute('src', recordSrc)
            playerNode.play()
        } else {
            $playerWrapper.hide()
            playerNode.pause()
        }
        $elem.toggleClass('play_record stop_record');
        $elem.children().toggleClass('fa-play fa-stop');
    };

    /* TODO: extract common parts with origin calls for later usage */
    /* DataTable section*/
    (function () {
        let tableBodyOffset = {}
        let height = 0
        let width = 0

        $('#client_mobile_app_voice_records_table').DataTable({
            responsive: true,
            iDisplayLength: 50,
            order: [],
            columnDefs: [
                { orderable: false, targets: 'no-sort' },
                { className: 'record', targets: 7 },
                { sType: 'ru_date_time', aTargets: [0] }
            ],
            language: {
                search: "<span>Поиск:</span> _INPUT_", //search
                lengthMenu: "<span>Показать записи:</span> _MENU_", //label
                searchPlaceholder: "Введите ≥ 3 символов",

            },
            serverSide: true,
            drawCallback: () => {
                $('#mobile-app-voice-records-table-processing').hide()
                $('#client_mobile_app_voice_records_table tbody').css("filter", "grayscale(0)")

                $('.play_stop_record').on('click', function () {
                    window.playStopVoiceRecord($(this), '.record-player');
                })
            },
            ajax: {
                url: '/clients/mobile_app_voice_records/table_data',
                type: 'POST',
                beforeSend: (xhr, settings) => {
                    let $tableBody = $('#client_mobile_app_voice_records_table tbody')
                    tableBodyOffset = $tableBody.offset()
                    height = $tableBody.height()
                    width = $tableBody.width()
                    let $appRecordsProcessing = $('#mobile-app-voice-records-table-processing')
                    $appRecordsProcessing.show()
                    $appRecordsProcessing.css(Object.assign(tableBodyOffset, { width: width, height: height }))
                    $tableBody.css("filter", "grayscale(100%)")
                    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
                    settings.data = settings.data + '&' + $('#mobile_app_voice_records_top_form').serialize()
                },
                data: (data) => {
                    return { table_data: data }
                }
            }
        })

        let prevTableSearchValLength = 0
        $("#client_mobile_app_voice_records_table_wrapper .dataTables_filter input").unbind().on('input', function () {
            let $dataTable = $('#client_mobile_app_voice_records_table').DataTable()
            if (this.value.length >= 3) {
                $dataTable.search(this.value).draw();
            }
            if (this.value.length <= 0 && prevTableSearchValLength > 0) {
                $dataTable.search("").draw();
            }
            prevTableSearchValLength = this.value.length
        });

    }).call()
});
$(document).ready(function () {
  window.checkPartPreroll = function (selector) {
    const SPEED = 13 //количество символов в секунду для генератора речи
    const MAX_PREROLL_DURATION = 40
    let internal_calls_text = 'для связи напрямую наберите звёздочку и внутренний номер'
    $(selector + ' .preroll-result-text').text('');
    $(selector + ' .preroll-part-text').each(function () {
      if (this.disabled) {
        $(selector + ' .preroll-result-text').append('<br>');
      } else {
        $(selector + ' .preroll-result-text').append(this.value + '<br>');
      }
    });
    if ($(selector + ' .info-about-internal-calls').is(':checked') && ($(selector + ' .preroll-result-text').text().indexOf(internal_calls_text) <= 0)){
      $(selector + ' .preroll-result-text').append(internal_calls_text);
    } else {
      $(selector + ' .preroll-result-text').html(function(_, txt) {
        return txt.replace(internal_calls_text, '');
      });
    }
    const preroll_duration = Math.round(parseFloat($(selector + ' .preroll-result-text').text().length) / SPEED);
    const activate_btn = $(selector + ' .activate-btn');
    const preroll_time = $(selector + ' .preroll-time');
    if (preroll_duration > MAX_PREROLL_DURATION) {
      activate_btn.attr('disabled', true);
      preroll_time.addClass('text-danger');
      preroll_time.text(`${preroll_duration} сек. Длительность не должна превышать ${MAX_PREROLL_DURATION} сек.`);
    } else {
      activate_btn.attr('disabled', false);
      preroll_time.removeClass('text-danger');
      preroll_time.text(`${preroll_duration} сек`);
    }
  };

  window.turnOnOffPreroll = function (selector) {
    let text = $(selector + ' .preroll-part-text.first-part').val();
    if ($(selector + ' .preroll-part-text.first-part').is(':disabled')) {
      $(selector + ' .preroll-result-text').prepend(text)
      $(selector + ' .preroll-part-text.first-part').removeAttr('disabled');
    } else {
      $(selector + ' .preroll-result-text').html(function (_, txt) {
        return txt.replace(text, '');
      });
      $(selector + ' .preroll-part-text.first-part').attr('disabled', 'disabled');
    }
    checkPartPreroll(selector);
  };

  window.fetchIndex = function (selector) {
    let indexes = []
    $.each([0, 1, 2, 3, 4, 5, 6, 7, 8], function (index, value) {
      if (index < $(selector + ' .optional-ivr-menu .row').length) {
        indexes.push(parseInt($(selector + ' .optional-ivr-menu .row:eq(' + index + ')').find('span:eq(0)').text()))
      }
    });
    let resultArray = [1, 2, 3, 4, 5, 6, 7, 8, 9].filter(x => !indexes.sort().includes(x));
    return resultArray[0]
  }

  window.addMenuItem = function (selector) {
    if ($(selector + ' .optional-ivr-menu .row').length == 0) {
      $(selector + ' .optional-ivr-menu').append(ivrMenuDivBlock());
    } else {
      if (fetchIndex(selector) == 1) {
        $(selector + ' .optional-ivr-menu .row:eq(0)').before(ivrMenuDivBlock());
      } else {
        $(selector + ' .optional-ivr-menu .row:eq(' + (fetchIndex(selector) - 2) + ')').after(ivrMenuDivBlock());
      }
    }
    let ivr_index = fetchIndex(selector)
    $('span.with_index').html(ivr_index);
    $('textarea.with_index').attr('name', 'interactive_voice_response[text_parts][' + ivr_index + ']');
    $('select.with_index').attr('name', 'interactive_voice_response[phone_licence_ids][' + ivr_index + ']');
    $('span.with_index, textarea.with_index, select.with_index').removeClass('with_index')
    if ($(selector + ' .optional-ivr-menu .row').length > 8) {
      $(selector + ' .add-ivr-menu-row').hide(100)
    }
    checkPartPreroll(selector);
    $(selector + ' .remove-ivr-menu').on('click', function () {
      removeMenuItem(this);
    })
    $('.card-body.ivr .preroll-part-text').on('change', function () {
      checkPartPreroll('.card-body.ivr');
    });
  };

  window.removeMenuItem = function (selector) {
    $(selector).parents('.ivr-menu').remove();
    if ($('.optional-ivr-menu .row').length < 9) {
      $('.add-ivr-menu-row').show(100)
    }
  };

  window.usePrerollAsPreroll = function (selector) {
    $(selector + ' #ownPreroll').show();
    $(selector + ' .custom-file-input').removeAttr('disabled');
    $(selector + ' .preroll-result-text').parent().hide();
    $(selector + ' .preroll-part-text').attr('disabled', 'disabled');
    $(selector + ' .preroll-time').hide();
  };

  window.useTextAsPreroll = function (selector) {
    $(selector + ' #ownPreroll').hide();
    $(selector + ' .custom-file-input').attr('disabled', 'disabled');
    $(selector + ' .preroll-result-text').parent().show();
    $(selector + ' .preroll-part-text').removeAttr('disabled');
    $(selector + ' .preroll-time').show();
  };

  window.useCustomTextAsPreroll = function (selector) {
    useTextAsPreroll(selector);
    $(selector + ' .use-custom-text-as-preroll').hide();
    $(selector + ' .upload-own-preroll').css('display', 'block').show();
    $(selector + ' .custom-file input').val('');
    checkPartPreroll(selector);
  };

  window.uploadOwnPreroll = function (selector) {
    usePrerollAsPreroll(selector);
    $(selector + ' .use-custom-text-as-preroll').css('display', 'block').show();
    $(selector + ' .upload-own-preroll').hide();
    $(selector + ' .activate-btn').attr("disabled", false);
  };

  window.turnOnOffTextFields = function (selector) {
    if ($(selector + ' .upload-own-preroll').is(':visible')) {
      $(selector + ' .preroll-part-text').not('.preroll-part-text.first-part').removeAttr('disabled');
    } else {
      $(selector + ' .preroll-part-text').not('.preroll-part-text.first-part').attr('disabled', 'disabled');
    }
  }

  /* обновление версии для конвертации при редактировании текстовых полей */
  $body.on('change', '.card-body.ivr .preroll-part-text', function () {
    checkPartPreroll('.card-body.ivr');
  });

  /* добавление или удаление текста приветствия из версии для конвертации в преролл */
  $body.on('click', '.card-body.ivr .without-preroll', function () {
    turnOnOffPreroll('.card-body.ivr');
    $(this).hide();
    $('.card-body.ivr .with-preroll').css('display', 'block').show();
  });

  $body.on('click', '.card-body.ivr .with-preroll', function () {
    turnOnOffPreroll('.card-body.ivr');
    $(this).hide();
    $('.card-body.ivr .without-preroll').css('display', 'block').show();
    useCustomTextAsPreroll('.card-body.ivr');
  });

  /* добавление пунктов меню при нажатии на + или "добавить пункт меню" */
  $body.on('click', '.card-body.ivr .add-new-ivr-menu', function () {
    addMenuItem('.card-body.ivr');
    turnOnOffTextFields('.card-body.ivr')
  })

  /* удаление пунктов меню при нажатии на корзину */
  $body.on('click', '.card-body.ivr .remove-ivr-menu', function () {
    removeMenuItem(this);
    checkPartPreroll('.card-body.ivr');
  })

  /* удаление пунктов меню при нажатии на корзину */
  $body.on('click', '.card-body.subivr .remove-ivr-menu', function () {
    removeMenuItem(this);
    checkPartPreroll('.card-body.subivr');
  })

  /* скрытие загрузчика преролла и показ тектовых полей */
  $body.on('click', '.card-body.ivr .use-custom-text-as-preroll', function () {
    useCustomTextAsPreroll('.card-body.ivr')
  })

  /* скрытие текстовых полей и показ загрузчика преролла */
  $body.on('click', '.card-body.ivr .upload-own-preroll', function () {
    uploadOwnPreroll('.card-body.ivr')
  })

  $body.on('change', '.card-body.ivr .info-about-internal-calls', function () {
    checkPartPreroll('.card-body.ivr');
  });



  /* ========== SUB IVR ========== */

  window.removeOverlay = function () {
    $('#activeIvr').modal('hide');
    $('#activeSubIvr').modal('hide');
    $('.ivr-overlay').hide();
    $('.sub-ivr').hide();
  }

  /* удалить оверлэй и скрыть форму второго уровня IVR */
  $body.on('click', '.ivr-overlay', function () {
    removeOverlay();
  });

  /* добавление пунктов меню при нажатии на + или "добавить пункт меню" */
  $body.on('click', '.card-body.subivr .add-new-ivr-menu', function () {
    addMenuItem('.card-body.subivr');
    turnOnOffTextFields('.card-body.subivr');
    $('.card.sub-ivr').css('min-height', ($('.card-body.subivr').height() + 90) + 'px');
    $('.card.sub-ivr optgroup[label="IVR второго уровня"]').remove()
  })

  /* скрытие загрузчика преролла и показ тектовых полей */
  $body.on('click', '.card-body.subivr .use-custom-text-as-preroll', function () {
    useCustomTextAsPreroll('.card-body.subivr')
  })

  /* скрытие текстовых полей и показ загрузчика преролла */
  $body.on('click', '.card-body.subivr .upload-own-preroll', function () {
    uploadOwnPreroll('.card-body.subivr')
  })

  /* обновление версии для конвертации при редактировании текстовых полей */
  $body.on('change', '.card-body.subivr .preroll-part-text', function () {
    checkPartPreroll('.card-body.subivr');
  });

  /* добавление или удаление текста приветствия из версии для конвертации в преролл */
  $body.on('click', '.card-body.subivr .without-preroll', function () {
    turnOnOffPreroll('.card-body.subivr');
    $(this).hide();
    $('.card-body.subivr .with-preroll').show();
  });

  $body.on('click', '.card-body.subivr .with-preroll', function () {
    turnOnOffPreroll('.card-body.subivr');
    $(this).hide();
    $('.card-body.subivr .without-preroll').show();
    useCustomTextAsPreroll('.card-body.subivr');
  });
});
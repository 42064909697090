$(function () {
  function getRuDateWeight(dateStr) {
    if (dateStr.match(/\./)) {
      var dateSplitted = dateStr.split('.');
      var Year = dateSplitted[2];
      var Month = dateSplitted[1];
      var Day = dateSplitted[0];
      var date_in_seconds = new Date(Year, parseInt(Month) - 1, Day).getTime()
      return date_in_seconds
    } else {
      return 0
    }
  }

  function getRuTimeWeight(dateStr) {
    if (dateStr.match(/\s/)) {
      var dateSplitted = dateStr.split(' ')[0].split('.');
      var timeSplitted = dateStr.split(' ')[1].split(':');
      var Year = dateSplitted[2];
      var Month = dateSplitted[1];
      var Day = dateSplitted[0];
      var Minute = timeSplitted[1];
      var Hour = timeSplitted[0];
      var datetime_in_seconds = new Date(Year, parseInt(Month) - 1, Day, Hour, Minute).getTime()
      return datetime_in_seconds
    } else {
      return 0
    }
  }

  $.extend($.fn.dataTable.defaults, {
    language: {
      "processing": "Подождите...",
      "search": "Поиск:",
      "lengthMenu": "Показать _MENU_ записей",
      "info": "Записи с _START_ до _END_ из _TOTAL_ записей",
      "infoEmpty": "Записи с 0 до 0 из 0 записей",
      "infoFiltered": "(отфильтровано из _MAX_ записей)",
      "loadingRecords": "Загрузка записей...",
      "zeroRecords": "Записи отсутствуют.",
      "emptyTable": "В таблице отсутствуют данные",
      "paginate": {
        "first": "Первая",
        "previous": "Предыдущая",
        "next": "Следующая",
        "last": "Последняя"
      },
      "aria": {
        "sortAscending": ": активировать для сортировки столбца по возрастанию",
        "sortDescending": ": активировать для сортировки столбца по убыванию"
      },
      "select": {
        "rows": {
          "_": "Выбрано записей: %d",
          "1": "Выбрана одна запись"
        },
        "cells": {
          "1": "1 ячейка выбрана",
          "_": "Выбрано %d ячеек"
        },
        "columns": {
          "1": "1 столбец выбран",
          "_": "%d столбцов выбрано"
        }
      },
      "searchBuilder": {
        "conditions": {
          "string": {
            "startsWith": "Начинается с",
            "contains": "Содержит",
            "empty": "Пусто",
            "endsWith": "Заканчивается на",
            "equals": "Равно",
            "not": "Не",
            "notEmpty": "Не пусто",
            "notContains": "Не содержит",
            "notStarts": "Не начинается на",
            "notEnds": "Не заканчивается на"
          },
          "date": {
            "after": "После",
            "before": "До",
            "between": "Между",
            "empty": "Пусто",
            "equals": "Равно",
            "not": "Не",
            "notBetween": "Не между",
            "notEmpty": "Не пусто"
          },
          "number": {
            "empty": "Пусто",
            "equals": "Равно",
            "gt": "Больше чем",
            "gte": "Больше, чем равно",
            "lt": "Меньше чем",
            "lte": "Меньше, чем равно",
            "not": "Не",
            "notEmpty": "Не пусто",
            "between": "Между",
            "notBetween": "Не между ними"
          },
          "array": {
            "equals": "Равно",
            "empty": "Пусто",
            "contains": "Содержит",
            "not": "Не равно",
            "notEmpty": "Не пусто",
            "without": "Без"
          }
        },
        "data": "Данные",
        "deleteTitle": "Удалить условие фильтрации",
        "logicAnd": "И",
        "logicOr": "Или",
        "title": {
          "0": "Конструктор поиска",
          "_": "Конструктор поиска (%d)"
        },
        "value": "Значение",
        "add": "Добавить условие",
        "button": {
          "0": "Конструктор поиска",
          "_": "Конструктор поиска (%d)"
        },
        "clearAll": "Очистить всё",
        "condition": "Условие",
        "leftTitle": "Превосходные критерии",
        "rightTitle": "Критерии отступа"
      },
      "searchPanes": {
        "clearMessage": "Очистить всё",
        "collapse": {
          "0": "Панели поиска",
          "_": "Панели поиска (%d)"
        },
        "count": "{total}",
        "countFiltered": "{shown} ({total})",
        "emptyPanes": "Нет панелей поиска",
        "loadMessage": "Загрузка панелей поиска",
        "title": "Фильтры активны - %d",
        "showMessage": "Показать все",
        "collapseMessage": "Скрыть все"
      },
      "thousands": ",",
      "buttons": {
        "pageLength": {
          "_": "Показать 10 строк",
          "-1": "Показать все ряды"
        },
        "pdf": "PDF",
        "print": "Печать",
        "collection": "Коллекция <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
        "colvis": "Видимость столбцов",
        "colvisRestore": "Восстановить видимость",
        "copy": "Копировать",
        "copyKeys": "Нажмите ctrl or u2318 + C, чтобы скопировать данные таблицы в буфер обмена.  Для отмены, щелкните по сообщению или нажмите escape.",
        "copySuccess": {
          "1": "Скопирована 1 ряд в буфер обмена",
          "_": "Скопировано %ds рядов в буфер обмена"
        },
        "copyTitle": "Скопировать в буфер обмена",
        "csv": "CSV",
        "excel": "Excel"
      },
      "decimal": ".",
      "infoThousands": ",",
      "autoFill": {
        "cancel": "Отменить",
        "fill": "Заполнить все ячейки <i>%d<i><\/i><\/i>",
        "fillHorizontal": "Заполнить ячейки по горизонтали",
        "fillVertical": "Заполнить ячейки по вертикали"
      },
      "datetime": {
        "previous": "Предыдущий",
        "next": "Следующий",
        "hours": "Часы",
        "minutes": "Минуты",
        "seconds": "Секунды",
        "unknown": "Неизвестный",
        "amPm": [
          "AM",
          "PM"
        ],
        "months": {
          "0": "Январь",
          "1": "Февраль",
          "10": "Ноябрь",
          "11": "Декабрь",
          "2": "Март",
          "3": "Апрель",
          "4": "Май",
          "5": "Июнь",
          "6": "Июль",
          "7": "Август",
          "8": "Сентябрь",
          "9": "Октябрь"
        },
        "weekdays": [
          "Вс",
          "Пн",
          "Вт",
          "Ср",
          "Чт",
          "Пт",
          "Сб"
        ]
      },
      "editor": {
        "close": "Закрыть",
        "create": {
          "button": "Новый",
          "title": "Создать новую запись",
          "submit": "Создать"
        },
        "edit": {
          "button": "Изменить",
          "title": "Изменить запись",
          "submit": "Изменить"
        },
        "remove": {
          "button": "Удалить",
          "title": "Удалить",
          "submit": "Удалить",
          "confirm": {
            "_": "Вы точно хотите удалить %d строк?",
            "1": "Вы точно хотите удалить 1 строку?"
          }
        },
        "multi": {
          "restore": "Отменить изменения",
          "title": "Несколько значений",
          "noMulti": "Это поле должно редактироватся отдельно, а не как часть групы"
        },
        "error": {
          "system": "Возникла системная ошибка (<a target=\"\\\" rel=\"nofollow\" href=\"\\\">Подробнее<\/a>)"
        }
      },
      "searchPlaceholder": "Что ищете?"
    }
  });

  jQuery.fn.dataTableExt.oSort['ru_date_time-asc'] = function (a, b) {
    return ((getRuTimeWeight(a) < getRuTimeWeight(b)) ? -1 : 1);
  };

  jQuery.fn.dataTableExt.oSort['ru_date_time-desc'] = function (a, b) {
    return ((getRuTimeWeight(a) > getRuTimeWeight(b)) ? -1 : 1);
  };

  jQuery.fn.dataTableExt.oSort['ru_date-asc'] = function (a, b) {
    return ((getRuDateWeight(a.split(' ')[0]) < getRuDateWeight(b.split(' ')[0])) ? -1 : 1);
  };

  jQuery.fn.dataTableExt.oSort['ru_date-desc'] = function (a, b) {
    return ((getRuDateWeight(a.split(' ')[0]) > getRuDateWeight(b.split(' ')[0])) ? -1 : 1);
  };

  /*------ Admin Panel begin ------*/
  $("#clients_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    "aaSorting": [],
    "pageLength": 100
  });

  $("#agents_table").DataTable({
    "responsive": true,
    "autoWidth": false
  });

  $("#voip_files_table").DataTable({
    "responsive": true,
    "autoWidth": false
  });

  $("#tariffs_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 10
  });

  $("#client_activities_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 0, "desc" ]]
  });

  $("#balance_change_increase_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 1, "desc" ]],
    'columnDefs': [
      {'sType': 'ru_date_time', 'aTargets': [1]}
    ]
  });

  $('#client_phone_applications_table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 50,
    "order": [],
    'columnDefs': [
      {'orderable': false, 'targets': 'no-sort'},
      {'sType': 'ru_date_time', 'aTargets': [4]}
    ]
  });

  $('#strange_calls').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 0, "desc" ]],
    'columnDefs': [
      {'sType': 'ru_date_time', 'aTargets': [0]}
    ]
  });

  $('#system_errors_table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 1, "desc" ]],
    'columnDefs': [
      { 'sType': 'ru_date_time', 'aTargets': [1] },
      { 'orderable': false, 'targets': 'no-sort' }
    ]
  });


  /*------ Admin Panel end ------*/

  /*------ Client begin ------*/
  $("#phones_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [],
    'columnDefs': [
      {'orderable': false, 'targets': 'no-sort'},
      {'sType': 'ru_date', 'aTargets': [3]},
      {'sType': 'ru_date', 'aTargets': [4]}
    ]
  });

  $("#client_mobile_app_managers_table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 50,
    "order": [],
    'columnDefs': [
      {'orderable': false, 'targets': 'no-sort'},
      {'sType': 'ru_date_time', 'aTargets': [5]}
    ]
  });

  $("#inactive_phones-table").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [],
    'columnDefs': [
      {'orderable': false, 'targets': 'no-sort'},
      {'sType': 'ru_date', 'aTargets': [3]},
      {'sType': 'ru_date', 'aTargets': [4]}
    ]
  });

  $("#payments_report").DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [],
    'columnDefs': []
  });

  $('#invoices-table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 10,
    "order": [[ 0, "desc" ]],
    'columnDefs': [
      {'sType': 'ru_date_time', 'aTargets': [0]}
    ]
  });

  $('#client_acts_table').DataTable({
    "responsive": true,
    "autoWidth": false,
    'iDisplayLength': 25,
    "order": [[ 0, "desc" ]],
    'columnDefs': []
  });
  /*------ Client end ------*/
});

window.initReportFilter = () => {
  $("#filter_with_compare").on("click", () => {
    $("#filter_disable_date").toggle();
    $("#filter_compare_date_range_str").toggle();
  });
};

window.initInstanceSelector = () => {
  const $instanceSelector = $(".report-instance-selector");
  $instanceSelector.select2({
    minimumResultsForSearch: -1,
    placeholder: "Выбрать",
    language: {
      noResults: function () {
        return "Результатов не найдено";
      },
    },
    escapeMarkup: function (markup) {
      return markup;
    },
  });

  $instanceSelector.on("change", () => {
    $instanceSelector.closest(".simple_form").submit();
  });
};

window.initInstancesInfoTable = () => {
  const $instancesInfoTable = $("#instances_info_table");
  $instancesInfoTable.DataTable({
    aLengthMenu: [
      [25, 50, 100, 200, -1],
      [25, 50, 100, 200, "Все"],
    ],
    responsive: true,
    autoWidth: false,
    iDisplayLength: 50,
    order: [[0, "asc"]],
  });

  let prevPageLen;
  window.addEventListener("beforeprint", () => {
    prevPageLen = $instancesInfoTable.DataTable().page.len();
    $instancesInfoTable.DataTable().page.len(-1).draw();
  });

  window.addEventListener("afterprint", () => {
    $instancesInfoTable.DataTable().page.len(prevPageLen).draw();
  });
};

window.initOutgoingCallsChart = (chartData) => {
  const selector = "#outgoing-calls-chart-container";
  const columns = () => {
    if (chartData.compare) {
      return [
        ["Звонков (основной)"].concat(chartData.main.outgoingAmounts),
        ["Звонков (сравнительный)"].concat(chartData.compare.outgoingAmounts),
      ];
    }

    return [["Звонков"].concat(chartData.main.outgoingAmounts)];
  };

  c3.generate({
    bindto: selector,
    data: {
      columns: columns(),
      type: "bar",
      colors: {
        ["Звонков"]: "#f0ad4e",
        ["Звонков (основной)"]: "#f0ad4e",
        ["Звонков (сравнительный)"]: "#EFD28D",
      },
    },
    axis: {
      x: {
        show: false,
        type: "category",
        categories: chartData.employeeNames,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      y: {
        show: true,
      },
    },
  });

  window.addEventListener("beforeprint", () => {
    $(selector).hide();
  });

  window.addEventListener("afterprint", () => {
    $(selector).show();
  });
};

window.initCallsDistributionChart = (chartData) => {
  const selector = "calls-distribution";
  const datasets = () => {
    if (chartData.compare) {
      return [
        {
          label: "Распределение (основное)",
          data: chartData.main.attemptsAmountInPercents,
          backgroundColor: "rgb(91, 192, 222, 0.3)",
          borderColor: "rgb(91, 192, 222, 1)",
          pointBackgroundColor: "rgb(91, 192, 222, 1)",
          pointBorderColor: "rgb(91, 192, 222, 1)",
          borderWidth: 1,
        },
        {
          label: "Распределение (сравнительный)",
          data: chartData.compare.attemptsAmountInPercents,
          backgroundColor: "rgb(108, 117, 125, 0.1)",
          borderColor: "rgb(108, 117, 125, 1)",
          pointBackgroundColor: "rgb(108, 117, 125, 1)",
          pointBorderColor: "rgb(108, 117, 125, 1)",
          borderWidth: 1,
        },
      ];
    }

    return [
      {
        label: "Распределение",
        data: chartData.main.attemptsAmountInPercents,
        backgroundColor: "rgb(91, 192, 222, 0.3)",
        borderColor: "rgb(91, 192, 222, 1)",
        pointBackgroundColor: "rgb(91, 192, 222, 1)",
        pointBorderColor: "rgb(91, 192, 222, 1)",
        borderWidth: 1,
      },
    ];
  };

  new Chart(selector, {
    type: "radar",
    data: {
      labels: chartData.employeeNames,
      datasets: datasets(),
    },
    options: {
      tooltips: {
        callbacks: {
          title: function () {
            return null;
          },
          beforeLabel: function (tooltipItem, data) {
            return data["labels"][tooltipItem["index"]];
          },
          label: function (tooltipItem, data) {
            return data["datasets"][0]["data"][tooltipItem["index"]] + "%";
          },
        },
      },
      scale: {
        pointLabels: {
          callback: function () {
            return null;
          },
        },
        ticks: {
          callback: function (value, index, values) {
            return value + "%";
          },
          beginAtZero: true,
        },
      },
    },
  });
};

window.initIncomingCallsChart = () => {
  let $hiddenBars;
  window.addEventListener("beforeprint", () => {
    $hiddenBars = $(".incoming-progress-items > div.progress-group:hidden");
    $(".incoming-progress-items > div.progress-group").show();
  });

  window.addEventListener("afterprint", () => {
    $hiddenBars.hide();
  });

  $("#incoming-calls-chart-container .progress").tooltip();
  (function initIncomingChartPagination() {
    let currentPage = 1;
    let lastPage = parseInt($(".page-link").eq(-2).text());
    let $nextPage = $("#nextPageIncomingStat");
    let $prevPage = $("#prevPageIncomingStat");
    let $pageNum = $(".page-num");
    let $bars = $(".incoming-progress-items > div.progress-group");
    if (currentPage === lastPage) $nextPage.addClass("disabled");

    window.goToPage = function (pageNum) {
      $bars.slice(0, (pageNum - 1) * 11).hide();
      $bars.slice(pageNum * 10, lastPage * 10).hide();
      $bars.slice((pageNum - 1) * 10, 10 * pageNum).show();
      $(".page-num.active").removeClass("active");
      $pageNum.eq(pageNum - 1).addClass("active");
      $nextPage.parent("li").removeClass("disabled");
      $prevPage.parent("li").removeClass("disabled");
      if (pageNum === lastPage) {
        $nextPage.parent("li").addClass("disabled");
        $prevPage.parent("li").removeClass("disabled");
      }
      if (pageNum === 1) {
        $prevPage.parent("li").addClass("disabled");
        $nextPage.parent("li").removeClass("disabled");
      }
      currentPage = pageNum;
    };

    window.nextPage = function () {
      if (currentPage === lastPage) return;

      $pageNum.eq(currentPage - 1).removeClass("active");
      $pageNum.eq(currentPage).addClass("active");
      if (currentPage === lastPage - 1)
        $nextPage.parent("li").addClass("disabled");
      $prevPage.parent("li").removeClass("disabled");
      let $bars = $(".incoming-progress-items > div.progress-group");
      $bars.slice(0, currentPage * 10).hide();
      $bars.slice(currentPage * 10, 10 * (currentPage + 1)).show();
      currentPage++;
    };

    window.prevPage = function () {
      if (currentPage === 1) return;

      $pageNum.eq(currentPage - 1).removeClass("active");
      $pageNum.eq(currentPage - 2).addClass("active");
      if (currentPage === 2) $prevPage.parent("li").addClass("disabled");
      $nextPage.parent("li").removeClass("disabled");
      let $bars = $(".incoming-progress-items > div.progress-group");
      $bars.slice((currentPage - 1) * 10, lastPage * 10).hide();
      $bars.slice(10 * (currentPage - 2), (currentPage - 1) * 10).show();
      currentPage--;
    };
  })();
};

window.initAttemptsByHourTable = () => {
  const selector = "#attempts-by-hour-table";

  const getScroll = () => {
    return (
      $(".date-labels").eq(window.currentIndex).offset().left -
      $(selector).offset().left -
      97
    );
  };

  $("#attempts-by-hour-table a.scroll").on("click", function () {
    if (window.currentIndex === undefined) {
      window.currentIndex = 0;
      window.lastSroll = 0;
    }

    let $tableContainer = $("#attempts-by-hour-table div.inner");
    let currentScroll = $tableContainer.scrollLeft();
    if (
      window.lastSroll !== undefined &&
      Math.abs(currentScroll - window.lastSroll) >= 1
    ) {
      window.currentIndex = Math.round(currentScroll / 90);

      $tableContainer.scrollLeft(currentScroll + getScroll());

      window.lastSroll = $tableContainer.scrollLeft();
      return;
    }

    let elemId = $(this).attr("id");
    if (elemId === "scroll_left") {
      if (window.currentIndex > 0) {
        window.currentIndex--;
      }
      window.lastSroll = $tableContainer.scrollLeft();
      $tableContainer.scrollLeft(currentScroll + getScroll());
    } else if (elemId === "scroll_right") {
      if (
        window.currentIndex + Math.ceil($tableContainer[0].clientWidth / 90) <=
        $(".date-labels").length
      ) {
        window.currentIndex++;
      }
      $tableContainer.scrollLeft(currentScroll + getScroll());
    }
    window.lastSroll = $tableContainer.scrollLeft();
  });

  window.addEventListener("beforeprint", () => {
    $(selector).hide();
  });

  window.addEventListener("afterprint", () => {
    $(selector).show();
  });
};

window.initMissedCallsChart = (chartData) => {
  if (chartData.compare) {
    window.addEventListener("beforeprint", () => {
      const $missedCallsCharts = $(".missed-calls-chart");
      $missedCallsCharts.addClass("col-md-6");
      $missedCallsCharts.eq(1).addClass("text-right");
    });

    window.addEventListener("afterprint", () => {
      const $missedCallsCharts = $(".missed-calls-chart");
      $missedCallsCharts.removeClass("col-md-6");
      $missedCallsCharts.eq(1).removeClass("text-right");
    });
  }

  if (
    chartData.main.answeredAmount === 0 &&
    chartData.main.answeredAmount === chartData.main.unansweredAmount
  ) {
    chartData.main.answeredAmount = 1;
    chartData.main.unansweredAmount = 0;
  }

  const mainChart = c3.generate({
    bindto: "#missed-calls-chart",
    data: {
      columns: [
        ["Отвеченные", chartData.main.answeredAmount],
        ["Пропущенные", chartData.main.unansweredAmount],
      ],
      type: "donut",
      colors: {
        ["Отвеченные"]: "#17a2b8",
        ["Пропущенные"]: "#dc3545",
      },
    },
    donut: {
      title: chartData.compare ? "Текущий" : null,
    },
  });
  mainChart.focus("Пропущенные");

  if (chartData.compare) {
    if (
      chartData.compare.answeredAmount === 0 &&
      chartData.compare.answeredAmount === chartData.compare.unansweredAmount
    ) {
      chartData.compare.answeredAmount = 1;
      chartData.compare.unansweredAmount = 0;
    }

    let compareChart = c3.generate({
      bindto: "#missed-compare-calls-chart",
      data: {
        columns: [
          ["Отвеченные", chartData.compare.answeredAmount],
          ["Пропущенные", chartData.compare.unansweredAmount],
        ],
        type: "donut",
        colors: {
          ["Отвеченные"]: "#17a2b8",
          ["Пропущенные"]: "#dc3545",
        },
      },
      donut: {
        title: "Сравнительный",
      },
    });
    compareChart.focus("Пропущенные");
  }
};

window.initUnansweredStatusesChart = (withCompare) => {
  $(".unanswered-bars .progress").tooltip();

  if (withCompare) {
    const selector = ".unanswered-bars";

    window.addEventListener("beforeprint", () => {
      $(selector).addClass("col-md-12");
    });

    window.addEventListener("afterprint", () => {
      $(selector).removeClass("col-md-12");
    });
  }
};

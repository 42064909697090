$(function () {
  $('.date-range-picker').daterangepicker({
    "minYear": 2020,
    "autoApply": false,
    "locale": {
      "format": "DD.MM.YYYY",
      "separator": " - ",
      "applyLabel": "Подтвердить",
      "cancelLabel": "Отмена",
      "fromLabel": "C",
      "toLabel": "По",
      "customRangeLabel": "Custom",
      "weekLabel": "W",
      "daysOfWeek": [
        "ВС",
        "ПН",
        "ВТ",
        "СР",
        "ЧТ",
        "ПТ",
        "СБ"
      ],
      "monthNames": [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      "firstDay": 1
    },
    "minDate": "04/01/2020",
    "opens": "left"
  }, function (start, end, label) {
    console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
  });

  $('.single-date-picker').daterangepicker({
    "singleDatePicker": true,
    "timePicker": false,
    "minYear": 2020,
    "timePicker24Hour": true,
    "autoApply": false,
    "locale": {
      "format": "DD.MM.YYYY",
      "separator": " - ",
      "applyLabel": "Подтвердить",
      "cancelLabel": "Отмена",
      "fromLabel": "C",
      "toLabel": "По",
      "customRangeLabel": "Custom",
      "weekLabel": "W",
      "daysOfWeek": [
        "ВС",
        "ПН",
        "ВТ",
        "СР",
        "ЧТ",
        "ПТ",
        "СБ"
      ],
      "monthNames": [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      "firstDay": 1
    }
  })
});

import consumer from "./consumer"

consumer.subscriptions.create("MissedCallsDashboardChannel", {
    connected() {
        console.log('Websocket turned on')
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        console.log('Websocket turned off')
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (window.location.href.match('missed_calls_dashboard')) {
            print_debug('Call params:')
            print_debug(data["content"])
            print_debug('duration = ' + data.content['duration'])
            print_debug('event_name = ' + data.content['event_name'])
            if (data.content['duration'] != 0 && data.content['duration'] != null) {
                print_debug('Start block with not zero call duration | Remove call row')
                let phone_number = get_phone_number(data.content)
                print_debug('phone_number = ' + phone_number)
                $('tbody tr[data-phone-number="' + phone_number + '"]').animate({backgroundColor: '#90ee90'}, 'slow');
                $('tbody tr[data-phone-number="' + phone_number + '"]').fadeOut(1000);
                setTimeout(remove_call_card, 1000, phone_number);
                print_debug('Finish block with not zero call duration | Remove call row')
            } else if ((data.content['duration'] == 0 || data.content['duration'] == null) && data.content['event_name'].match('call_end')) {
                print_debug('Start block with zero call duration and event_name like call_end')
                let missed_call = $('tr[data-phone-number="' + data.content["caller"] + '"]')
                if (missed_call.length > 0) {
                    print_debug('Update row with missed call')
                    let call_selector = 'tr[data-phone-number="' + data.content["caller"] + '"] .calls-count'
                    let calls_count = parseInt($(call_selector).text())
                    remove_call_card(data.content["caller"])
                    $('tbody').append(buildCallRow(data, calls_count + 1))
                } else {
                    print_debug('Add row with missed call')
                    var rows = $(buildCallRow(data, 1));
                    rows.hide();
                    $('tbody').append(rows)
                    rows.fadeIn(1000);
                }
                print_debug('Finish block with zero call duration and event_name like call_end')
            } else {
                print_debug('Check params')
            }
        }
    },
});

window.buildCallRow = function (data, calls_count) {
    return '<tr data-phone-number="' + data.content["caller"] + '">' +
        '<td class="calls-count">' + calls_count + '</td><td>' + data.content["strftime_time"] + '</td>' +
        '<td>' + data.content["caller"] + '</td><td>' + data.content["receiver"] + ' (' + data.content["name"] + ')</td>' +
        '<td><a data-remote="true" rel="nofollow" data-method="post" ' +
        'href="/clients/missed_calls_dashboard/remove_from_table?client_uuid=' + data.content['client_uuid'] +
        '&number=' + data.content["caller"].replace("+", "%2B") + '">' +
        '<i class="fa fa-trash" title="удалить"></i>' +
        '</a></td></tr>'
}

window.remove_call_card = function (phone_number) {
    $('tr[data-phone-number="' + phone_number + '"]').remove()
}

window.get_phone_number = function (call_data) {
    if (call_data['action'].match("process_receiver")) {
        return call_data["caller"]
    } else {
        return call_data["receiver"]
    }
}

window.print_debug = function (text) {
    if (typeof debug !== 'undefined') {
        console.log(text)
    }
}

window.initEmpSelect = (phoneLicences) => {
    $('.employee-calls-select').select2({
        multiple: true,
        placeholder: "Все",
        "dropdownAutoWidth": true,
        "language": {
            "noResults": function () {
                return "Результатов не найдено";
            }
        },
        escapeMarkup: function (markup) {
            return markup;
        }
    }).on('change', () => {
        let $phoneLicence = $('#phone_licences')
        if ($phoneLicence.val() != null && $phoneLicence.val().includes("all")) {
            $phoneLicence.val(null).trigger('change')
        }
    }).on('select2:select select2:unselect', () => {
        let $phoneLicence = $('#phone_licences')
        if ($phoneLicence.val() != null) $phoneLicence.select2("open")
    });
    $('#phone_licences').val(phoneLicences).trigger("change");
}

$(function () {

    new Plyr($('.record-player > .plyr-player'));
    let playerNode = $('.record-player audio').get(0)
    let $playerWrapper = $('.record-player')
    let $lastOriginCallRecord
    const resizeObserverForPlayer = new ResizeObserver(() => {

        if ($lastOriginCallRecord !== undefined) {
            let offset = $lastOriginCallRecord.offset()
            let windowWidth = $(window).width()
            if (windowWidth < 999) {
                offset.left = windowWidth / 2 - 195
            } else {
                offset.left -= 470
            }
            offset.top -= 5
            $playerWrapper.offset(offset)
        }
    }).observe($('section.content').get(0))
    $('#origin-call-modal').on('scroll', function () {
        if ($playerWrapper.hasClass('record-modal-player') && $lastOriginCallRecord !== undefined) {
            let offset = $lastOriginCallRecord.offset()
            offset.top -= 5
            offset.left = $playerWrapper.offset().left
            $playerWrapper.offset(offset)
        }
    })
    window.playStopVoiceRecord = function ($elem, player_class) {
        if ($elem.hasClass('play_record')) {
            $('.play_stop_record.stop_record').click()
            $playerWrapper.attr('class', player_class.slice(1))
            let offset = $elem.offset()
            $lastOriginCallRecord = $elem
            offset.left -= 470
            offset.top -= 5
            $playerWrapper.show()
            $playerWrapper.offset(offset)
            let recordSrc = $elem.attr('record_src')
            playerNode.setAttribute('src', recordSrc)
            playerNode.play()
        } else {
            $playerWrapper.hide()
            playerNode.pause()
        }
        $elem.toggleClass('play_record stop_record');
        $elem.children().toggleClass('fa-play fa-stop');
    };
    $('#calls-xlsx-download').on('click', function () {
        $form = $('#origin_calls_top_form');
        $form.attr('action', $form.data('data-xlsx-path'));
        $form.submit();
        $form.attr('action', $form.data('data-origin-path'));
    });

    (function () {
        // callsDataTable
        let tableBodyOffset = {}
        let height = 0
        let width = 0

        let searchBySpeechConfig = {
          dom:
            '<"row"' +
            '<"col-sm-12 col-md-6"l>' +
            '<"col-sm-12 col-md-6"' +
            '<"row"' +
            '<"col-sm-11 float-right"f>' +
            '<"col-sm-1 float-right"B>' +
            ">" +
            ">" +
            ">" +
            '<"row"' +
            '<"col-sm-12"t>' +
            ">" +
            '<"row"' +
            '<"col-sm-12 col-md-5"i>' +
            '<"col-sm-12 col-md-7"p>' +
            ">",
          buttons: [
            {
              className: "btn btn-sm btn-light search-by-speech-btn",
              text: "<i class='font-weight-bold'>T</i>",
              action: function (_e, table, button, _config) {
                button.toggleClass('active');
                $('#search_by_speech').val(button.hasClass('active'));
                if (table.search().length) {
                  table.search(table.search()).draw();
                }
              },
              titleAttr: "Поиск по расшифровке разговора",
            },
          ],
        };

        let dataTableConfig = {
          responsive: true,
          iDisplayLength: 50,
          order: [],
          columnDefs: [
            { orderable: false, targets: 'no-sort' },
            { className: 'record', targets: 9 },
            { sType: 'ru_date_time', aTargets: [0] }
          ],
          language: {
            search: "<span>Поиск:</span> _INPUT_", //search
            lengthMenu: "<span>Показать записи:</span> _MENU_", //label
            searchPlaceholder: "Введите ≥ 3 символов",

          },
          serverSide: true,
          drawCallback: () => {
            $('#calls-table-processing').hide()
            $('#client_calls_table tbody').css("filter", "grayscale(0)")

            $('.play_stop_record').on('click', function () {
              window.playStopVoiceRecord($(this), '.record-player');
            })
          },
          ajax: {
            url: '/clients/origin_calls/table_data',
            type: 'POST',
            beforeSend: (xhr, settings) => {
              let $tableBody = $('#client_calls_table tbody')
              tableBodyOffset = $tableBody.offset()
              height = $tableBody.height()
              width = $tableBody.width()
              let $callsProcessing = $('#calls-table-processing')
              $callsProcessing.show()
              $callsProcessing.css(Object.assign(tableBodyOffset, { width: width, height: height }))
              $tableBody.css("filter", "grayscale(100%)")
              settings.data = settings.data + '&' + $('#origin_calls_top_form').serialize()
            },
            data: (data) => {
              return { table_data: data }
            }
          }
        }

        if ($("#search_by_speech").length) {
          dataTableConfig = {...dataTableConfig, ...searchBySpeechConfig}
        }

        $('#client_calls_table').DataTable(dataTableConfig)

        let prevTableSearchValLength = 0
        $("#client_calls_table_wrapper .dataTables_filter input").unbind().on('input', function () {
            let $dataTable = $('#client_calls_table').DataTable()
            if (this.value.length >= 3) {
                $dataTable.search(this.value).draw();
            }
            if (this.value.length <= 0 && prevTableSearchValLength > 0) {
                $dataTable.search("").draw();
            }
            prevTableSearchValLength = this.value.length
        });

    }).call()
});

$(document).ready(function () {

    window.calculateAndSetPrice = function () {
        window.calculateLicencePrices()
        $("input#own_payment_count").attr("value", $("span#finalPrice").text())
    };

    $(".payment-amount-radio").on('click', function () {
        $('.payment-amount-radio').css('border', '1px solid gray')
        $(this).css('border','2px solid #17a2b8')
        $('#own_payment_count').val($(this).parents('.form-check-inline').find('input').val())
    });

    $("#custom_own_payment").on('click', function () {
        $("#own_payment_count").show().prop("disabled", false)
    });

    $(".standart-payment").on('click', function () {
        $("#own_payment_count").hide().prop("disabled", true)
    });

    $("#own_payment_count").on('change', function () {
        $("#custom_own_payment").attr("value", $("#own_payment_count").val())
    });

    $("#price_calculator").on('change', function () {
        if ($('#price_calculator').is(':checked')) {
            $('.price_calculator').show(400)
            $('#licence_count').removeAttr('disabled')
            window.calculateAndSetPrice()
            calculator.setVoiceStorageValues()
        } else {
            $('.price_calculator').hide(400)
            $('#licence_count').attr('disabled', 'disabled')
        }
    });

    $("input[name=licence_count]").on('change', function () {
        window.calculateAndSetPrice()
    });

    $('#with_prolongation').on('change', function (){
        window.calculateAndSetPrice()
    });

    $('#with_voice_storage').on('change', function (){
        window.calculateAndSetPrice()
    });
})

window.initFormInputs = function () {
  /*init select with search*/
  $('.js-select-auto-complete').select2({
    "language": {
      "noResults": function () {
        return "Результатов не найдено";
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    }
  });
  $('.js-select-for-prerol').select2({
    minimumResultsForSearch: -1,
    placeholder: "Проигрывать файл",
    "language": {
      "noResults": function () {
        return "Результатов не найдено";
      }
    },
    escapeMarkup: function (markup) {
      return markup;
    }
  });
  /*remove green valid color if inner fields are invalid*/
  $('.with-hidden-inner-input').each(function () {
    if ($(this).find('.invalid-feedback').length > 0) {
      $(this).find('.is-valid').addClass('is-invalid');
      $(this).find('.is-valid').removeClass('is-valid')
    }
  });
  Inputmask({ "mask": "+375 (99) 999-99-99" }).mask($('.phone-with-mask'));


};

window.closeRightFormSidebar = function () {
  $("#right-form-sidebar").ControlSidebar('collapse');
  $('#right-form-sidebar-content').html('');
  $('#page_overlay').hide()
};

$(function () {
  $body = $('body');

  $body.on('click', '.tr-link, .remote-link', function () {
    $('#right-form-sidebar-content').html('');
    $('#right-form-sidebar .overlay').show();
    $("#right-form-sidebar").ControlSidebar('show');
    $('#page_overlay').show()
    $.ajax($(this).data('ajax-url'))
  });

  $body.on('click', '.td-link', function () {
    $('#right-form-sidebar-content').html('');
    $('#right-form-sidebar .overlay').show();
    $("#right-form-sidebar").ControlSidebar('show');
    $('#page_overlay').show()
    $.ajax($(this).parents('tr').data('ajax-url'))
  });

  $body.on('click', '.close-form-sidebar, #page_overlay', function () {
    window.closeRightFormSidebar()
  });

  $body.on('click', '.open-related-input', function () {
    $(this).siblings('.input-in-a-hidden-state-content').show();
    $(this).remove()
  });

  $body.on('click', '.open-hidden-inner-input-checkbox', function () {
    $content_to_show = $(this).parents('.with-hidden-inner-input').find('.hidden-inner-input-content');
    if ($(this).is(':checked')) {
      $content_to_show.slideDown(500);
    } else {
      $content_to_show.slideUp(500);
    }
  });

  window.initFormInputs();

  $('.content-loaded-via-ajax').each(function () {
    $.ajax($(this).data('ajax-url'));
  });

  $body.on('change', '.with-play-example select', function () {
    $container = $(this).parents('.with-play-example');
    $container.find('audio')[0].pause();
    audio_src = $(this).find('option:selected').data('src');
    $container.find('audio').attr('src', audio_src)
  });

  $body.on('click', '.play_form_file_example', function () {
    $container = $(this).parents('.with-play-example');
    $container.find('.voice_file_payer').slideDown(500);
    let audio = $container.find('audio')[0]
    new Plyr(audio);
    audio.play();
    $(this).replaceWith('<a class="stop_form_file_example" href="javascript:void(0)">\n' +
        '<i class="far fa-stop-circle"></i></a>')
  })

  $body.on('click', '.stop_form_file_example', function () {
    $container = $(this).parents('.with-play-example');
    $container.find('.voice_file_payer').slideUp(500);
    $container.find('audio')[0].pause();
    $(this).replaceWith('<a class="play_form_file_example" href="javascript:void(0)">\n' +
        '<i class="far fa-play-circle" aria-hidden="true"></i></a>')
  })

  $('.header-helper').tooltip({
    placement: function (tip, element) {
      if (document.body.clientWidth - element.getBoundingClientRect().left < 1000) {
        return "bottom"
      }
      return "right"

    }
  });

  window.copyToClipboard = function (element) {
    let $elem = (element instanceof jQuery ? element : $(element))
    if ($elem.is('input')) {
      let isDisabled = $elem.prop('disabled')
      if (isDisabled) $elem.prop('disabled', false)

      $elem.select()
      document.execCommand("copy")
      if (isDisabled) $elem.prop('disabled', true)

      return
    }
    let $tempInput = $("<input>")
    $("body").append($tempInput)
    $tempInput.val($elem.text()).select()
    document.execCommand("copy")
    $tempInput.remove()
  }
});

$(document).ready(function () {

  $('#invoice_detail_entrepreneur').on('change', function () {
    if ($('#invoice_detail_entrepreneur').is(":checked")) {
      $('input[data-entrepreneur-example="true"], input[data-entrepreneur="true"]').prop('disabled', true).parent().hide(400);
      $('label[for="invoice_detail_work_rank_and_name_var_1"], label[for="invoice_detail_work_rank_and_name_var_1_example"]')
        .text('ФИО руководителя в именительном падеже');
      $('#invoice_detail_work_rank_and_name_var_1_example').val('Иванов Иван Иванович')
      $('.invoice_detail_dogovor, .invoice_detail_dogovor_example').show(400)
      $('#invoice_detail_dogovor').prop('disabled', false)
    } else {
      $('input[data-entrepreneur-example="true"]').parent().show(400);
      $('input[data-entrepreneur="true"]').prop('disabled', false).parent().show(400)
      $('label[for="invoice_detail_work_rank_and_name_var_1"], label[for="invoice_detail_work_rank_and_name_var_1_example"]')
        .text('Должность и ФИО руководителя в именительном падеже');
      $('#invoice_detail_work_rank_and_name_var_1_example').val('директор Иванов Иван Иванович')
      $('.invoice_detail_dogovor, .invoice_detail_dogovor_example').hide(400)
      $('#invoice_detail_dogovor').prop('disabled', true)
    }
  });

});
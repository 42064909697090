window.initClientEmpSelect = function (ids) {
    $('#client-employee-select').select2({
        multiple: true,
        placeholder: "Нет доступов",
        "dropdownAutoWidth": true,
        "language": {
            "noResults": function () {
                return "Результатов не найдено";
            }
        },
        escapeMarkup: function (markup) {
            return markup;
        }
    }).on('change', (e) => {
        let $phoneLicence = $(e.target)
        if ($phoneLicence.val() != null && $phoneLicence.val().includes("all") && $phoneLicence.val().length !== 1) {
            $phoneLicence.val('all').trigger('change')
        }
        if ($phoneLicence.val().length === 0) {
            $('.with-resources').css({filter: 'grayscale(100%)'})
            $('.with-resources .header-resource').css({color: 'rgba(0, 0, 0, 0.50)'})
            let $inputs = $('.with-resources input:not(:disabled)')
            $inputs.addClass('no-resource-disable')
            $inputs.prop('disabled', true);
        } else {
            $('.with-resources').css({filter: 'grayscale(0%)'})
            $('.with-resources .header-resource').css({color: '#343a40 !important'})
            let $inputs = $('.with-resources input.no-resource-disable')
            $inputs.prop('disabled', false);
            $inputs.removeClass('no-resource-disable')
        }
    })

    if ($('#client-employee-select').length > 0 && $('#client-employee-select').val().includes("all")) {
        $('#client-employee-select').val(null).trigger('change')
    }

    $('#client-employee-select').val(ids).trigger("change");
}
$(document).ready(function () {

    $('.checkbox-read input[type="radio"]').on('change', function (e) {
        let $ch = $(e.target)
        let $all = $('input[name="' + $ch[0].name.replace('read', 'update') + '"]:checked')
        if ($ch.val() < $all.val()) {
            $('input[name="' + $ch[0].name.replace('read', 'update') + '"][value="' + $ch.val() + '"]').prop('checked', true);
        }
    })

    $('.checkbox-edit input[type="radio"]').on('change', function (e) {
        let $ch = $(e.target)
        let $all = $('input[name="' + $ch[0].name.replace('update', 'read') + '"]:checked')
        if ($ch.val() > $all.val()) {
            $('input[name="' + $ch[0].name.replace('update', 'read') + '"][value="' + $ch.val() + '"]').prop('checked', true);
        }
    })


})